document.addEventListener("DOMContentLoaded", () => {
    // Handle generic GTM click events on links using the data-gtm attribute as its payload...
    document.querySelectorAll('[data-gtm]').forEach((element) => {
        element.addEventListener("click", (event) => {
            if (typeof window['google_tag_manager'] !== 'undefined') {
                event.preventDefault();

                const payload = JSON.parse(event.target.dataset.gtm);
                const targetUrl = event.target.href;

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    ...payload,
                    'eventCallback': () => { document.location = targetUrl; },
                    'eventTimeout' : 2000
                });
            }
        });
    });
});
