import {MapboxAddressMinimap } from '@mapbox/search-js-web';

export default function (Alpine) {
    Alpine.directive('minimap', minimap)

    function minimap(el, { expression }, { evaluate }) {
        const expressionData = evaluate(expression)

        let latitude = getValueFromExpressionData(expressionData, 'latitude')
        let longitude = getValueFromExpressionData(expressionData, 'longitude')

        let minimap = mapboxInstance();

        el.appendChild(minimap)

        setCoordinates(minimap, latitude, longitude)
    }
}

function mapboxInstance() {
    const minimap = new MapboxAddressMinimap()
    const icons = {
        marker: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width=".5" stroke="rgba(0,0,0,0.2)" fill="none" class="w-12 h-12 drop-shadow-lg">
            <path fill="#fff" stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
            <path fill="#13a4ea" stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
        `
      }

    minimap.accessToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN
    minimap.defaultMapStyle = ['distortedfusion', 'clox0rfqz010x01qo0ctd18hf']
    minimap.theme = {
        icons: icons
    }

    return minimap;
}

function setCoordinates(minimap, latitude, longitude) {
    minimap.feature = {
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: [longitude, latitude]
        },
        properties: {}
    };
    minimap.canAdjustMarker = false;
}

function getValueFromExpressionData(data, key) {
    if (! data.hasOwnProperty(key)) {
        throwError()
    }

    const rawValue = data[key]

    if (rawValue === undefined || rawValue === null) {
        throwError()
    }

    return rawValue
}

function throwError() {
    throw new Error('Missing expression for x-minimap directive, both x-minimap.latitude and x-minimap.longitude must be provided and can not be empty.')
}
