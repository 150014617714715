import {Map} from 'mapbox-gl';

export default function (Alpine) {
    Alpine.directive('flyover', flyover)

    function flyover(el, { expression }, { evaluate }) {
        const expressionData = evaluate(expression);

        let points = getValueFromExpressionData(expressionData, 'points');

        mapboxInstance(el, points).then((map) => {
            cycle(map, points)
        });
    }
}

function cycle(map, points, index = 0)
{
    if (points.features.length === 0) {
        return;
    }

    let feature = points.features[index];

    map.once('moveend', () => {
        window.setTimeout(() => {
            cycle(map, points, (index + 1) % points.features.length)
        }, 3000);
    });

    map.flyTo({
        center: feature.geometry.coordinates,
        zoom: 11
    });
}

function mapboxInstance(el, points) {
    return new Promise((resolve, reject) => {
        let firstLocation = points.features.length !== 0
            ? points.features[0].geometry.coordinates
            : [3.889029, 51.501721];

        const markers = {
            'marker-ecommit': '/img/map/ecommit-marker.png',
        };
        const map = new Map({
            container: el,
            style: 'mapbox://styles/distortedfusion/clox0rfqz010x01qo0ctd18hf',
            center: firstLocation,
            zoom: 11,
            interactive: false,
            accessToken: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
        });

        map.on('load', () => {
            let markerPromises = [];

            // Prepare a list of promises for all custom marker images...
            for (const [key, value] of Object.entries(markers)) {
                markerPromises.push(new Promise((resolve, reject) => {
                    map.loadImage(value, (error, image) => {
                        if (error) throw error;

                        map.addImage(key, image);

                        resolve(key);
                    });
                }));
            }

            // Once the markers have been loaded inject the points layer...
            Promise.all(markerPromises).then(() => {
                map.addSource('points', {
                    type: 'geojson',
                    data: points,
                    cluster: false,
                });

                map.addLayer({
                    id: 'unclustered-points',
                    type: 'symbol',
                    source: 'points',
                    layout: {
                        'icon-image': 'marker-ecommit',
                        'icon-size': 0.5,
                    }
                });
            }).then(() => {
                resolve(map);
            });
        });
    });
}

function getValueFromExpressionData(data, key) {
    if (! data.hasOwnProperty(key)) {
        throwError()
    }

    const rawValue = data[key]

    if (rawValue === undefined || rawValue === null) {
        throwError()
    }

    return rawValue
}

function throwError() {
    throw new Error('Missing expression for x-flyover directive, locations must be provided.')
}
