import { Map, Marker, NavigationControl, FullscreenControl, Popup } from 'mapbox-gl';
import { template } from 'lodash';

export default function (Alpine) {
    Alpine.directive('map', map)

    function map(el, { expression }, { evaluate }) {
        const expressionData = evaluate(expression)

        let points = getValueFromExpressionData(expressionData, 'points');
        let center = getValueFromExpressionData(expressionData, 'center', true);
        let embed = !! getValueFromExpressionData(expressionData, 'embed', true);
        let fullscreenHref = getValueFromExpressionData(expressionData, 'fullscreenHref');
        let locale = getValueFromExpressionData(expressionData, 'locale');

        mapboxInstance(el, points, center, embed, fullscreenHref, locale);
    }
}

const markerCache = {};
let activeMarkers = {};

function mapboxInstance(el, points, center, embed, fullscreenHref, locale) {
    return new Promise((resolve, reject) => {
        let asEmbed = !! embed;
        let defaultLocation = [3.889029, 51.501721];
        let centerLocation = center !== null ? center : defaultLocation;

        let defaultZoom = asEmbed ? 7 : 11;
        let zoomLevel = center !== null ? 18 : defaultZoom;

        const map = new Map({
            container: el,
            style: 'mapbox://styles/distortedfusion/clox0rfqz010x01qo0ctd18hf',
            projection: 'mercator',
            center: centerLocation,
            zoom: zoomLevel,
            accessToken: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
        })

        map.addControl(new NavigationControl(), 'bottom-right');

        if (asEmbed) {
            const fullscreenControl = new FullscreenControl();
            fullscreenControl._onClickFullscreen = () => window.location.href = fullscreenHref;

            map.addControl(fullscreenControl);
        }

        map.on('load', () => {
            map.addSource('points', {
                type: 'geojson',
                data: points,
                cluster: true,
            })

            map.addLayer({
                id: 'unclustered-points',
                type: 'circle',
                source: 'points',
                filter: ['!', ['has', 'point_count']],
                paint: {
                    // This adds a hidden circle behind the custom markers,
                    // used for the click handlers.
                    'circle-color': '#000',
                    'circle-opacity': 0,
                    'circle-radius': 24
                }
            });

            map.addLayer({
                id: 'clustered-points',
                type: 'circle',
                source: 'points',
                filter: ['has', 'point_count'],
                paint: {
                    // This adds a hidden circle behind the custom markers,
                    // used for the click handlers.
                    'circle-color': '#000',
                    'circle-opacity': 0,
                    'circle-radius': 24
                }
            });

            // Inspect cluster on click, this zooms in onto the cluster...
            map.on('click', 'clustered-points', (e) => {
                const features = map.queryRenderedFeatures(e.point, {
                    layers: ['clustered-points']
                });
                const clusterId = features[0].properties.cluster_id;

                map.getSource('points').getClusterExpansionZoom(clusterId, (err, zoom) => {
                    if (err) return;

                    map.easeTo({
                        center: features[0].geometry.coordinates,
                        zoom: zoom
                    });
                });
            });

            // Handle custom markers...
            map.on('render', () => {
                if (map.isSourceLoaded('points')) {
                    updateMarkers(map);
                }
            });

            // Handle popups...
            map.on('click', 'unclustered-points', (e) => {
                const coordinates = e.features[0].geometry.coordinates.slice();
                const properties = e.features[0].properties;
                const hrefs = JSON.parse(e.features[0].properties.hrefs);

                properties.href = hrefs[locale];

                map.flyTo({
                    center: coordinates,
                    offset: [0, 175],
                });

                new Popup({
                    className: 'font-sans w-80',
                    anchor: 'bottom',
                    maxWidth: 320,
                    closeButton: false,
                    focusAfterOpen: false,
                    offset: {
                        // Marker size: roof(94 / 4) + 5
                        'bottom': [0, -29],
                    }
                }).setLngLat(coordinates).setHTML(template(
                    document.getElementById('map-popup').innerHTML
                )(properties)).addTo(map);
            });

            // Handle cursor styling...
            map.on('mouseenter', ['clustered-points'], () => {
                map.getCanvas().style.cursor = 'pointer';
            });

            map.on('mouseleave', ['clustered-points'], () => {
                map.getCanvas().style.cursor = '';
            });
        })
    })
}

function updateMarkers(map) {
    const visibleMarkers = {};

    const points = map.getSource('points');
    const features = map.querySourceFeatures('points');

    let markerPromises = [];

    for (const feature of features) {
        markerPromises.push(new Promise((resolve, reject) => {
            const properties = feature.properties;
            const id = typeof(properties.cluster) === 'undefined'
                ? properties.id
                : properties.cluster_id;

            if (properties.cluster) {
                resolveChildren(map, id).then((features) => {
                    feature.properties.clustered_features = features;

                    resolve({id: id, instance: markerInstance(id, feature)})
                });
            } else {
                resolve({id: id, instance: markerInstance(id, feature)})
            }
        }));
    }

    Promise.all(markerPromises).then((markers) => {
        for (const marker of markers) {
            visibleMarkers[marker.id] = marker.instance;

            if (! activeMarkers[marker.id]) {
                marker.instance.addTo(map);
            }
        }

        removeInactiveMarkers(visibleMarkers);
    });
}

function resolveChildren(map, cluster_id)
{
    const points = map.getSource('points');

    return new Promise((resolve, reject) => {
        let featurePromises = [];

        points.getClusterChildren(cluster_id, (error, children) => {
            for (const child of children) {
                featurePromises.push(new Promise((resolve, reject) => {
                    if (typeof(child.properties.cluster) !== 'undefined') {
                        resolveChildren(map, child.properties.cluster_id).then((child_features) => {
                            resolve(child_features);
                        });
                    } else {
                        resolve([child]);
                    }
                }));
            }

            Promise.all(featurePromises).then((features) => {
                let uniqueFeatures = filterNestedArray(
                    features.flat(),
                    (left, right) => left.properties.client_slug == right.properties.client_slug
                );

                resolve(uniqueFeatures);
            });
        });
    });
}

function removeInactiveMarkers(visibleMarkers)
{
    for (const id in activeMarkers) {
        if (! visibleMarkers[id]) {
            activeMarkers[id].remove();
        }
    }

    activeMarkers = visibleMarkers;
}

function markerInstance(id, feature)
{
    const properties = feature.properties;
    const coords = feature.geometry.coordinates;

    if (markerCache[id]) {
        return markerCache[id];
    }

    const el = createHtmlMarker(properties);

    markerCache[id] = new Marker({
        element: el
    }).setLngLat(coords);

    return markerCache[id];
}

function createHtmlMarker(properties) {
    const el = document.createElement('div');

    el.innerHTML = template(
        document.getElementById('map-marker').innerHTML
    )(properties);

    return el.firstChild;
}

/**
 * @see https://blog.adriaan.io/make-a-javascript-array-with-objects-unique-by-its-nested-key.html
 */
function filterNestedArray(array, property) {
    const compare = typeof property === "function"
        ? property
        : (left, right) => left[property] == right[property];

    const newArray = [];

    array.forEach((right) => {
      const run = (left) => compare.call(this, left, right);
      var i = newArray.findIndex(run);
      if (i === -1) newArray.push(right);
    });

    return newArray;
}

function getValueFromExpressionData(data, key, optional = false) {
    if (! data.hasOwnProperty(key) && optional) {
        return null;
    }

    if (! data.hasOwnProperty(key)) {
        throwError(key);
    }

    const rawValue = data[key];

    if ((rawValue === undefined || rawValue === null) && ! optional) {
        throwError(key);
    }

    return rawValue;
}

function throwError(key) {
    throw new Error('Missing ['+key+'] expression for x-map directive, property must be provided.')
}
